import "./FooterContacts.css";

import { Container, IconButton } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import React from "react";
import {Link} from "react-router-dom";

import {useSocialMedia} from "../../api/TextsAPI";

function FooterContacts() {
	const { facebook, instagram, linkedIn } = useSocialMedia();
	return (
		<div className="footer-wrapper">
			<Container style={{ padding: 0 }}>
				<footer className="app-footer">
					<div className="footer-text">
						<p>@adhara 2020</p>
						<Link to="/faq">FAQ</Link>
						<Link to="/terms">Termos e Condições</Link>
						<Link to="/privacy">Política de Privacidade</Link>
						<Link to="/contact">Contacte&#8209;nos</Link>
						<Link to={{ pathname: "https://www.livroreclamacoes.pt/Inicio/" }} target="_blank" >Livro de Reclamações Online</Link>
					</div>
					<div className="footer-icons">
						<IconButton className="footer-icon" href={facebook} target="_blank" rel="noopener"><FacebookIcon/></IconButton>
						<IconButton className="footer-icon" href={instagram} target="_blank" rel="noopener"><InstagramIcon/></IconButton>
						<IconButton className="footer-icon" href={linkedIn} target="_blank" rel="noopener"><LinkedInIcon/></IconButton>
					</div>
				</footer>
			</Container>
		</div>
	);
}

export default FooterContacts;
