import React, {Suspense} from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";

import {SpecialCoursesProvider} from "../api/TextsAPI";
import GoogleAnalytics from "../components/analytics/GoogleAnalytics";
import AuthProvider from "../components/auth/AuthProvider";
import CookieBanner from "../components/cookies/CookieBanner";
import ErrorBoundary from "../components/error/ErrorBoundary";
import ImportFallback from "../components/error/ImportFallback";
import NetworkDetector from "../components/error/NetworkDetector";
import ScrollToTop from "../components/scroll/ScrollToTop";
import CartProvider from "../components/shopping/CartProvider";

const AppSkeleton = (props) => (
    <React.StrictMode>
        <Router>
            <ErrorBoundary>
                <ScrollToTop/>
                <AuthProvider>
                    <SpecialCoursesProvider>
                        <CartProvider>
                            <Suspense fallback={<ImportFallback/>}>
                                <Switch>
                                    {props.children}
                                </Switch>
                                <CookieBanner/>
                                <GoogleAnalytics/>
                                <NetworkDetector/>
                            </Suspense>
                        </CartProvider>
                    </SpecialCoursesProvider>
                </AuthProvider>
            </ErrorBoundary>
        </Router>
    </React.StrictMode>
);

export default AppSkeleton;