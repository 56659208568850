import {useEffect} from "react";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";

/* eslint import/no-named-as-default-member: 0 */

const GoogleAnalytics = () => {
    const history = useHistory();
    useEffect(() => {
        if (process.env.REACT_APP_ANALYTICS_TRACKING) {
            console.log("GA Initialized!");
            ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING, {
              //  debug: true,
            });
            history.listen(location => {
                ReactGA.set({page: location.pathname});
                ReactGA.pageview(location.pathname + location.search);
            })
        }
    }, [ history ]);


    return null
}

GoogleAnalytics.recordEvent = (category, action) => {
    ReactGA.event({ category, action });
}

export default GoogleAnalytics;