import React, { createContext, useContext, useReducer } from "react";
import { generateID } from "../../lib/NumberGenerator";
import { calculateTotal, CartReducer } from "./CartReducer";
import { getProductPrice } from "./getProductPrice";
const defaultCart = { items: [], total: 0, discount: "" };
export const CartContext = createContext(defaultCart);

function init() {
  const storage = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [];
  const discountCode = localStorage.getItem("discount")
    ? JSON.parse(localStorage.getItem("discount"))
    : "";
  return {
    items: storage,
    total: calculateTotal(storage),
    discount: discountCode,
  };
}

const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CartReducer, defaultCart, init);

  const addCourse = (course) => {
    dispatch({
      type: "ADD_ITEM",
      payload: {
        id: generateID(),
        type: "COURSE",
        itemId: course.id,
        name: course.name,
        price: course.price,
        amount: 1,
      },
    });
  };

  const addEvent = (event, ticketType, workshops, lunch) => {
    const workshopsDetails = [];
    for (const [, value] of Object.entries(workshops)) {
      //TODO this probably can be used inside the current workshop object. But needs to be done carefully. Do later
      workshopsDetails.push({
        id: parseInt(value),
        name: event.workshops.find((w) => w.id === parseInt(value)).name,
      });
    }
    dispatch({
      type: "ADD_ITEM",
      payload: {
        id: generateID(),
        type: "EVENT_TICKET",
        itemId: ticketType.id,
        name: event.name,
        price: ticketType.price,
        amount: 1,
        details: {
          workshopsDetails,
          workshops: workshops,
          lunch: lunch,
        },
      },
    });
  };

  const addTraining = (training) => {
    const originalPrice = training.price;
    const hasDiscount = training.priceWithDiscount > 0 ? true : false;
    const newPrice =
      training.price > training.priceWithDiscount
        ? training.priceWithDiscount
        : training.price;
    const directDiscountAmount = parseFloat(
      (training.price - training.priceWithDiscount).toFixed(2)
    );

    dispatch({
      type: "ADD_ITEM",
      payload: {
        id: generateID(),
        name: training.name,
        type: "TRAINING",
        itemId: training.id,
        price: newPrice,
        originalPrice,
        hasDirectDiscount: hasDiscount,
        directDiscountAmount,
        amount: 1,
        subscriptionEnabled: training.subscriptionEnabled,
        subscriptionDays: training.subscriptionDays,
      },
    });
  };

  const addProduct = (product, amount, details) => {
    //removing duplicates with dynamic options
    state.items.forEach((item) => {
      if (item.type === "PRODUCT") {
        //create a copy of the file and not the reference as it will change the original values as well
        let newItem = Object.assign({}, details);
        delete newItem.amount;
        delete newItem["Nome Bordado"];
        delete newItem["Logótipo"];

        let oldItem = Object.assign({}, item.details);
        delete oldItem.amount;
        delete oldItem["Nome Bordado"];
        delete oldItem["Logótipo"];

        if (
          item.itemId === product.id &&
          JSON.stringify(newItem) === JSON.stringify(oldItem)
        ) {
          removeProduct(item.id);
        }
      }
    });
    dispatch({
      type: "ADD_ITEM",
      payload: {
        id: generateID(),
        type: "PRODUCT",
        itemId: product.id,
        amount: amount,
        price: getProductPrice(product, amount, details),
        details: details,
      },
    });
  };

  const removeProduct = (id) =>
    dispatch({ type: "REMOVE_ITEM", payload: { id } });
  const clear = () => dispatch({ type: "CLEAR" });
  const applyDiscount = (discount) => {
    const applicableItems = state.items.filter(
      (item) => item.type === "TRAINING"
    );

    if (applicableItems.length === 0) {
      return {
        success: false,
        message: "Este desconto só é válido para items do tipo Formações.",
      };
    } else {
      dispatch({ type: "APPLY_DISCOUNT", payload: discount });
      return { success: true };
    }
  };

  const changeAmount = (id, amount, details) =>
    dispatch({ type: "CHANGE_AMOUNT", payload: { id, amount, details } });

  const checkValues = (items) =>
    dispatch({ type: "CHECK_ITEMS", payload: { items } });

  const removeDiscount = () => dispatch({ type: "REMOVE_DISCOUNT" });

  const contextValues = {
    addCourse,
    addEvent,
    addTraining,
    addProduct,
    removeProduct,
    clear,
    applyDiscount,
    changeAmount,
    checkValues,
    removeDiscount,
    ...state, // items, total, discount
  };

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;

const useShoppingCart = () => {
  return useContext(CartContext);
};

export { useShoppingCart };
