import { Container } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import styles from "./APIFetcher.module.css";
import CircularProgress from "./CircularProgress";
import LoadingContainer from "./LoadingContainer";

const APIFetcher = (props) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const [scrollEnded, setScrollEnded] = useState(false);

  const onScrollBottom = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    props.fetchItems(currentPage + 1, () => setScrollEnded(true));
  };

  // fetchItems(currPage, endScroll)
  useEffect(() => {
    setLoading(true);
    setHasError(false);
    setCurrentPage(0);
    setScrollEnded(false);
    props
      .fetchItems(0, () => {})
      .catch((err) => {
        console.log(err);
        setHasError(true);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...props.reloadOnChange]);

  const emptyStyle = {
    textAlign: "center",
    margin: "0.5em 0 2em 0",
    fontSize: "1.5em",
  };

  if (loading) return <LoadingContainer />;
  if (hasError)
    return <Container style={emptyStyle}>{props.errorMessage}</Container>;
  if (props.hasItems) {
    if (props.enablePagination && !scrollEnded) {
      return (
        <>
          {props.children}
          <VisibilitySensor
            onChange={(visible) => {
              if (visible) onScrollBottom();
            }}
          >
            <CircularProgress style={{ margin: "1em auto" }} />
          </VisibilitySensor>
        </>
      );
    } else return props.children;
  }
  if (props.emptyImage) {
    return (
      <Container style={emptyStyle}>
        <div className={styles.emptyContainer}>
            <div className={styles.emptyImage}>{props.emptyImage}</div>
          <div className={styles.emptyMessageContainer}>
            <div className={styles.emptyMessageTitle}>{props.emptyMessageTitle}</div>
            <div className={styles.emptyMessageContent}>{props.emptyMessage}</div>
          </div>
        </div>
      </Container>
    );
  } else return <Container style={emptyStyle}>{props.emptyMessage}</Container>;
};

APIFetcher.propTypes = {
  fetchItems: PropTypes.func.isRequired,
  hasItems: PropTypes.bool,
  errorMessage: PropTypes.string,
  emptyMessage: PropTypes.string,
  emptyMessageTitle: PropTypes.string,
  emptyImage: PropTypes.object,
  enablePagination: PropTypes.bool,
  reloadOnChange: PropTypes.array,
};

APIFetcher.defaultProps = {
  hasItems: true,
  enablePagination: false,
  reloadOnChange: [],
  errorMessage: "Erro: Não foi possível obter a informação do servidor.",
  emptyMessage: "Parece que não há nada aqui.",
};

export default APIFetcher;
