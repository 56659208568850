import './SimpleModal.css';

import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import React, {useEffect} from "react";

let classNames = require('classnames');

const sizeToClass = {
    small: "modal-sm",
    medium: "modal-md",
    large: "modal-lg",
}

const SimpleModal = (props) => {

    useEffect(() => {
        const closeOnEsc = (event) => { if(event.keyCode === 27) props.onClose() };
        document.addEventListener("keydown", closeOnEsc);

        return () => document.removeEventListener("keydown", closeOnEsc);
    }, [props])

    return (
        <Modal open={props.open} onClose={props.onClose}
               BackdropProps={{className: "simple-modal-backdrop"}} className={classNames("simple-modal-container", props.containerClassName)}>
            <div className={classNames("simple-modal", sizeToClass[props.size], props.className)}>
                <CloseIcon className="modal-close-icon" onClick={props.onClose}/>
                {props.children}
            </div>
        </Modal>
    )
}

export default SimpleModal;

SimpleModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    size: PropTypes.oneOf(Object.keys(sizeToClass))
}

SimpleModal.defaultProps = {
    size: "medium",
}