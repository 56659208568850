import PropTypes from "prop-types";
import React from "react";

import ResourceSearch from "../components/adhara/resources/ResourceSearch";
import ResourceTypeToName from "../components/adhara/resources/ResourceTypeToName";
import ResourcesSidebar from "../components/adhara/resources/ResourcesSidebar";
import AuthOnly from "../components/auth/AuthOnly";
import RegularLayout from "./RegularLayout";
import styles from './ResourceLayout.module.css';

const ResourceLayout = (props) => {
    const resourceType = props.resourceType;
    const resourceName = ResourceTypeToName({ resourceType: props.resourceType});

    return (
        <RegularLayout title={props.title || resourceName}>
            <AuthOnly>
                <div className={styles.container}>
                    <div className={styles.sidebar}>
                        <ResourcesSidebar resourceType={resourceType}/>
                    </div>

                    <div  className={styles.search}>
                        <ResourceSearch resourceType={resourceType}/>
                    </div>

                    <section className={styles.children}>
                        {props.children}
                    </section>
                </div>
            </AuthOnly>
        </RegularLayout>
    );
}


ResourceLayout.propTypes = {
    title: PropTypes.string,
    resourceType: PropTypes.string.isRequired,
    search: PropTypes.string,
}

export default ResourceLayout;