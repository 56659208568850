import React from "react";

import { ReactComponent as AllIconSVG} from "../../../assets/icons/resource/all.svg"
import { ReactComponent as CalculatorsSVG} from '../../../assets/icons/resource/calculator.svg';
import { ReactComponent as ConversorSVG } from '../../../assets/icons/resource/conversor.svg';
import { ReactComponent as FavoriteIconSVG} from "../../../assets/icons/resource/favorite.svg"
import { ReactComponent as LeafletSVG} from '../../../assets/icons/resource/folhetos.svg';
import { ReactComponent as GuidelinesSVG} from '../../../assets/icons/resource/guidelines.svg';
import { ReactComponent as InfographSVG} from '../../../assets/icons/resource/infograph.svg';
import { ReactComponent as PopularIconSVG} from "../../../assets/icons/resource/popular.svg"
import { ReactComponent as ExercisesSVG} from '../../../assets/icons/resource/prescrição.svg';
import { ReactComponent as TreesSVG} from '../../../assets/icons/resource/tree.svg';


export function CalculatorIcon(props) {
    return <CalculatorsSVG {...props}/>
}

export function TreesIcon(props) {
    return <TreesSVG {...props}/>
}

export function ConverterIcon(props) {
    return <ConversorSVG {...props}/>
}

export function GuidelineIcon(props) {
    return <GuidelinesSVG {...props}/>
}

export function InfographIcon(props) {
    return <InfographSVG {...props}/>
}
export function LeafletIcon(props){
    return <LeafletSVG {...props}/>
}

export function AllIcon(props) {
    return <AllIconSVG {...props}/>
}


export function FavoriteIcon(props) {
    return <FavoriteIconSVG {...props}/>
}


export function PopularIcon(props) {
    return <PopularIconSVG {...props}/>
}

export function ExerciseIcon(props){
    return <ExercisesSVG {...props}/>
}