import React  from 'react';
import LeafletForm from "./LeafletForm";


function Modal(props) {

    return (
      <div className = 'modal'>
        <LeafletForm submit = {props.onCancel} pid={props.pid}/>
      </div>
    );
  }
  export default Modal;
  