import axios from "axios";

import React, {useContext, useEffect, useState} from "react";
import {fetchCourses} from "./CourseAPI";
import useAPIItem from "./useAPIItem";

export const fetchTexts = () => axios.get("/api/texts").then(res => res.data)
export const fetchFAQs = () => fetchTexts().then(texts => texts.faqs);
export const fetchPrivacy = () => fetchTexts().then(texts => texts.privacy);
export const fetchTerms = () => fetchTexts().then(texts => texts.terms);

export function useSocialMedia() {
    const [socialLinks] = useAPIItem("/api/texts/social");
    return socialLinks
}

// NurseSeries
const SpecialCoursesContext = React.createContext([]);
export const SpecialCoursesProvider = (props) => {
    const [courses, setCourses] = useState([])
    useEffect(() => {
        fetchSpecialCourses().then(setCourses);
    }, [])

    return (
        <SpecialCoursesContext.Provider value={courses}>
            {props.children}
        </SpecialCoursesContext.Provider>
    )
}

export const useSpecialCourses = () => {
    return useContext(SpecialCoursesContext);
}

export const fetchSpecialCourses = async () => {
    const [courses, ids] = await Promise.all([fetchCourses(), fetchSpecialCourseIds()]);

    return courses.filter(c => ids.includes(c.id)).reverse();
}
export const fetchSpecialCourseIds = () => axios.get("/api/texts/special").then(res => res.data);




export function doContact(name, email, message) {
    return axios.post(`/api/contact/`, { name, email, message }).then(res => {
        if (!res.data.success)
            throw Error("Não foi possível completar a operação, por favor tente mais tarde.")
    });
}

export function editFAQs(content) {
    return axios.put("/api/texts/faq", { faqs: content }).then(res => res.data)
}

export function editPrivacy(content) {
    return axios.put("/api/texts/privacy", { privacy: content }).then(res => res.data)
}

export function editTerms(content) {
    return axios.put("/api/texts/terms", { terms: content }).then(res => res.data)
}

