import './Collapsable.css';

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PropTypes from "prop-types";
import React from "react";
import Collapsible from "react-collapsible";

const classNames = require("classnames");

const Collapsable = (props) => {
    return (
        <Collapsible
            open={props.open}
            trigger={<CollapsibleTrigger title={props.title}/>}
            transitionTime={150}
            className={classNames("collapsable", props.className)}
            openedClassName={classNames("collapsable", props.className)}
            triggerClassName={classNames("collapsable-button", "collapsable-button-closed", props.buttonClassName)}
            triggerOpenedClassName={classNames("collapsable-button", "collapsable-button-open", props.buttonClassName)}
            contentInnerClassName={classNames("collapsable-content", props.contentClassName)}
            tabIndex={0}
        >
            {props.children}
        </Collapsible>
    );
}

const CollapsibleTrigger = (props) => (
    <>
        {props.title}
        <KeyboardArrowDownIcon className="collapsable-button-icon"/>
    </>
)

Collapsable.propTypes = {
    title: PropTypes.any.isRequired,
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
    contentClassName: PropTypes.string
}


export default Collapsable;
