import React from 'react';
import {Redirect, Route} from "react-router-dom";

import Exercises from '../exercises/Exercises';
import LeafletList from '../leaflets//LeafletList';
import lazyImport from "../lib/LazyImport";

import ResourceListPage from "../pages/Resources/ResourceListPage" // css order, cba to fix this right

import AppSkeleton from "./AppSkeleton";
// const FavoritesPage = lazyImport(() => import("../testing/Favorites"))
const Home = lazyImport(() => import('../pages/Home'));
const MainNewsArticle = lazyImport(() => import("../pages/MainNewsArticle"));
const CertificatePage = lazyImport(() => import("../pages/CertificatePage"));
const Survey = lazyImport(() => import("../pages/Survey"));

const Events = lazyImport(() => import("../pages/Event/Events"));
const EventHome = lazyImport(() => import("../pages/Event/EventHome"));
const EventSponsors = lazyImport(() => import("../pages/Event/EventSponsors"));
const EventQuizzes = lazyImport(() => import("../pages/Event/EventQuizzes"));
const EventQuiz = lazyImport(() => import("../pages/Event/EventQuiz"));

const Courses = lazyImport(() => import("../pages/Course/Courses"));
const CourseHome = lazyImport(() => import("../pages/Course/CourseHome"));
const CourseLearning = lazyImport(() => import("../pages/Course/CourseLearning"));
const CourseSponsors = lazyImport(() => import("../pages/Course/CourseSponsors"));
const CourseQuizzes = lazyImport(() => import("../pages/Course/CourseQuizzes"));
const CourseQuiz = lazyImport(() => import("../pages/Course/CourseQuiz"));

const PartnerHome = lazyImport(() => import("../pages/Partner/PartnerHome"));

const Trainings = lazyImport(() => import("../pages/Training/Trainings"));
const TrainingLearning = lazyImport(() => import("../pages/Training/TrainingLearning"));
const TrainingHome = lazyImport(() => import("../pages/Training/TrainingHome"));

const SponsorMain = lazyImport(() => import("../pages/Sponsor/SponsorMain"));
const SponsorHome = lazyImport(() => import("../pages/Sponsor/SponsorHome"));
const SponsorNews = lazyImport(() => import("../pages/Sponsor/SponsorNews"));
const SponsorInfographs = lazyImport(() => import("../pages/Sponsor/SponsorInfographs"));
const SponsorVideos = lazyImport(() => import("../pages/Sponsor/SponsorVideos"));
const SponsorStudies = lazyImport(() => import("../pages/Sponsor/SponsorStudies"));
const SponsorLinks = lazyImport(() => import("../pages/Sponsor/SponsorLinks"));
const SponsorStandArticles = lazyImport(() => import("../pages/Sponsor/SponsorStandArticles"));
const SponsorDocuments = lazyImport(() => import("../pages/Sponsor/SponsorDocuments"));
const SponsorNewsArticle = lazyImport(() => import("../pages/Sponsor/SponsorNewsArticle"));
const SponsorStudy = lazyImport(() => import("../pages/Sponsor/SponsorStudy"));
const SponsorInfograph = lazyImport(() => import("../pages/Sponsor/SponsorInfograph"));

const Resources = lazyImport(() => import("../pages/Resources/Resources"));
const Calculator = lazyImport(() => import("../pages/Resources/Calculator"));
const DecisionTree = lazyImport(() => import("../pages/Resources/DecisionTree"));
const Guideline = lazyImport(() => import("../pages/Resources/Guideline"));
const InfographResource = lazyImport(() => import("../pages/Resources/InfographResource"));
//const ResourceListPage = lazyImport(() => import("../pages/Resources/ResourceListPage"));
const ExerciseList = lazyImport(() => import("../exercises/ExerciseList"))
const ExercisesToPdf = lazyImport(() => import("../exercises/ExercisesToPdf"))


const ShoppingCart = lazyImport(() => import("../pages/Shop/ShoppingCart"));
const FinalizePurchase = lazyImport(() => import("../pages/Shop/FinalizePurchase"));

const TermsOfService = lazyImport(() => import("../pages/Texts/TermsOfService"));
const PrivacyPolicy = lazyImport(() => import("../pages/Texts/PrivacyPolicy"));
const FAQ = lazyImport(() => import("../pages/Texts/FAQ"));
const Contact = lazyImport(() => import("../pages/Texts/Contact"));

const Register = lazyImport(() => import("../pages/Auth/Register"));
const Login = lazyImport(() => import("../pages/Auth/Login"));
const ProfileEdit = lazyImport(() => import("../pages/Auth/ProfileEdit"));
const ProfileItems = lazyImport(() => import("../pages/Auth/ProfileItems"));
const ForgotPassword = lazyImport(() => import("../pages/Auth/ForgotPassword"));
const ResetPassword = lazyImport(() => import("../pages/Auth/ResetPassword"));
const Error404 = lazyImport(() => import("../pages/Error/404"));
const Error403 = lazyImport(() => import("../pages/Error/403"));


//shop
const StoreMainPage = lazyImport(() => import("../store/StoreMainPage"))
const ProductPage = lazyImport(() => import("../store/ProductPage"))
const ValidateTicketDummy = lazyImport(() => import("../pages/Admin/Events/ValidateTicketDummy"))

// separar routes em ficheiros n dá, a nao ser q tenhas uma key ou q faças de uma maneira jiga joga
// https://stackoverflow.com/questions/43026690/declaring-react-routes-in-a-separate-file-and-importing


const PublicApp = (props) => (
    <AppSkeleton>
        <Route exact path="/" component={ Home } />

        <Route exact path="/store" component={ StoreMainPage } />
        <Route exact path="/store/products/:productId" component={ ProductPage } />

        <Route exact path="/news/:articleName--:articleId" component={ MainNewsArticle }/>

        <Route exact path="/events" component={ Events } />
        <Route exact path="/events/:eventName--:eventId" component={ EventHome } />
        <Route exact path="/events/:eventId" component={ EventHome } />
        <Route exact path="/events/:eventId/sponsors" component={ EventSponsors } />
        <Route exact path="/events/:eventId/quizzes" component={ EventQuizzes } />
        <Route exact path="/events/:eventId/quizzes/:quizId" component={ EventQuiz } />
        <Route exact path="/events/:eventId/certificate" component={CertificatePage}/>
        <Route exact path="/events/:eventId/survey" component={Survey}/>


        <Route exact path="/courses" component={ Courses } />
        <Route exact path="/courses/:courseName--:courseId" component={ CourseHome } />
        <Route exact path="/courses/:courseId" component={ CourseHome } />
        <Route exact path="/courses/:courseId/learning" component={ CourseLearning } />
        <Route exact path="/courses/:courseId/sponsors" component={ CourseSponsors } />
        <Route exact path="/courses/:courseId/quizzes" component={ CourseQuizzes } />
        <Route exact path="/courses/:courseId/quizzes/:quizId" component={ CourseQuiz } />
        <Route exact path="/courses/:courseId/certificate" component={CertificatePage}/>
        <Route exact path="/courses/:courseId/survey" component={Survey}/>

        <Route exact path="/trainings" component={ Trainings } />
        <Route exact path="/trainings/:trainingId--:sessionIndex--:sessionItem/lecture" component={ TrainingLearning } />
        <Route exact path="/trainings/:trainingId" component={ TrainingHome } />
        <Route exact path="/trainings/:trainingId/survey" component={Survey}/>
        <Route exact path="/trainings/:trainingId/certificate" component={CertificatePage}/>

        <Route exact path="/sponsors/stands/:standId" component={ SponsorMain } />
        <Route exact path="/sponsors/stands/:standId/home" component={ SponsorHome } />
        <Route exact path="/sponsors/stands/:standId/news" component={ SponsorNews } />
        <Route exact path="/sponsors/stands/:standId/infos" component={ SponsorInfographs } />
        <Route exact path="/sponsors/stands/:standId/videos" component={ SponsorVideos } />
        <Route exact path="/sponsors/stands/:standId/studies" component={ SponsorStudies } />
        <Route exact path="/sponsors/stands/:standId/links" component={ SponsorLinks } />
        <Route exact path="/sponsors/stands/:standId/documents" component={ SponsorDocuments } />
        <Route exact path="/sponsors/stands/:standId/standarticles" component={ SponsorStandArticles } />
        <Route exact path="/sponsors/stands/:standId/news/:articleId" component={SponsorNewsArticle}/>
        <Route exact path="/sponsors/stands/:standId/infos/:infoId" component={SponsorInfograph}/>
        <Route exact path="/sponsors/stands/:standId/studies/:studyId" component={SponsorStudy}/>

        <Route exact path="/resources" component={ Resources }/>
        <Route exact path="/resources/calculators/:resourceName--:resourceId" component={ Calculator }/>
        <Route exact path="/resources/tables/:resourceName--:resourceId" component={ Calculator }/>
        <Route exact path="/resources/trees/:resourceName--:resourceId" component={ DecisionTree }/>
        <Route exact path="/resources/guidelines/:resourceName--:resourceId" component={ Guideline }/>
        <Route exact path="/resources/infos/:resourceName--:resourceId" component={ InfographResource }/>
        <Route exact path="/resources/:resourceType(calculators|tables|trees|guidelines|infos)" component={ ResourceListPage }/>
        <Route exact path="/resources/leaflets" component={LeafletList} /> 
        <Route exact path="/resources/exercises" component={Exercises} />
        <Route exact path="/resources/exercises/:BodypartId" component={ExerciseList}/>
        <Route exact path="/resources/exercises/:BodypartId/send/:stringToSend" component={ExercisesToPdf}/>

        <Route exact path="/cart" component={ShoppingCart}/>
        <Route exact path="/cart/confirmed/:method(stripe|paypal|mb|mbway)" component={ShoppingCart}/>
        <Route exact path="/cart/finalize" component={FinalizePurchase}/>

        <Route exact path="/terms" component={TermsOfService}/>
        <Route exact path="/privacy" component={PrivacyPolicy}/>
        <Route exact path="/faq" component={FAQ}/>
        <Route exact path="/contact" component={ Contact } />

        <Route exact path="/register" component={ Register } />
        <Route exact path="/login" component={ Login } />
        <Route exact path="/profile/details" component={ ProfileEdit }/>
        <Route exact path="/profile/items/:tab" component={ ProfileItems }/>

        
        <Route exact path="/partner/details" component={ PartnerHome }/>

        <Route exact path="/password/forgot" component={ ForgotPassword } />
        <Route exact path="/password/reset/:token/:userId" component={ ResetPassword } />

        <Route exact path="/events/validate/dummy" component={ ValidateTicketDummy } />

        {props.children}
        <Route exact path="/404" component={ Error404 } />
        <Route exact path="/403" component={ Error403 } />
        <Redirect to="/404" />
    </AppSkeleton>
);

export default PublicApp;
