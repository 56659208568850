import './CustomScrollBar.css'

import React from "react";

const classNames = require("classnames");

const CustomScrollbar = ({ className, children, ...otherProps}) => (
    <div className={classNames("custom-scrollbar", className)} {...otherProps}>
        {children}
    </div>
)


export default CustomScrollbar;