import React  from "react";
import { Redirect,  useLocation } from "react-router-dom";

import { useAuth } from "./AuthProvider";

const AuthOnly = ({ allowUnconfirmed, children }) => {
    const user = useAuth()
    const location = useLocation();

    if (user === null)
        return <Redirect to={{pathname: "/login", state: { backURL: location.pathname}}}/>

    //We commented this out because it was preventing sales, we need to think a better approach
    // if (user && !user.isConfirmed && !allowUnconfirmed)
    //     return <Redirect to={{pathname: "/profile/details", state: { backURL: location.pathname}}}/>


    return <>{children}</>
}

export default AuthOnly;