import { Button } from "@material-ui/core";
import React from "react";
import "./ButtonBase.css";

import { useHistory } from "react-router-dom";

const classNames = require("classnames");

const ButtonBase = ({
  className,
  style,
  submit,
  href,
  externalLink,
  to,
  onClick,
  fill,
  ...otherProps
}) => {
  const styles = Object.assign(
    {
      fontSize: "1em",
      width: fill ? "100%" : "auto",
      height: fill ? "100%" : "auto",

      fontFamily: "inherit",
      fontWeight: 500,
      textTransform: "inherit",

      display: "flex",
      alignItems: "center",
      opacity: "1",
      transition: "all 0.1s ease-in-out",
      boxSizing: "border-box",

      minWidth: "auto",
    },
    style
  );

  const history = useHistory();

  return (
    <Button
      type={submit ? "submit" : "button"}
      href={href}
      target={externalLink ? "_blank" : null}
      rel={externalLink ? "noopener noreferrer" : null}
      onClick={to ? () => history.push(to) : onClick}
      className={classNames("base-button", className)}
      style={styles}
      {...otherProps}
    />
  );
};
export default ButtonBase;

ButtonBase.defaultProps = {
  onClick: () => console.log("Button clicked"),
  submit: false,
  fill: false,
  style: {},
};
