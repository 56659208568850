import React from "react";
import {Link} from "react-router-dom";

import ImageFluid from "../components/image/ImageFluid";
import styles from './SponsorButton.module.css';

const SponsorButton = ({ stand }) => (
    <Link to={`/sponsors/stands/${stand.id}`} className={styles.button}>
        <div className={styles.left}>
            <small>Apoiado por:</small>
            <strong>Saiba mais</strong>
        </div>
        <ImageFluid src={stand.Sponsor.logo} alt="Sponsor Logo"/>
    </Link>
)


export default SponsorButton;