import "./FooterNav.css";

import { Container } from '@material-ui/core';
import React from "react";
import {Link} from "react-router-dom";

import Form from "../forms/Form";
import FormSelect from "../forms/FormSelect";
import useFormKey from "../forms/useFormKey";

function FooterNav() {
    return (
        <div className="footer-nav">
            <Container>
                <footer>
                    <FooterNavItem header="Eventos">
                        <Link to="/events?when=future">Próximos Eventos</Link>
                        <Link to="/events?when=past">Eventos Anteriores</Link>
                        <Link to="/events?when=archived">Arquivo</Link>
                    </FooterNavItem>

                    <FooterNavItem header="Cursos Online">
                        <Link to="/courses?when=future">Próximos Cursos</Link>
                        <Link to="/courses?when=past">Cursos Anteriores</Link>
                        <Link to="/courses?when=archived">Arquivo</Link>
                    </FooterNavItem>

                    <FooterNavItem header="Formações">
                        <Link to="/trainings">Próximas Formações</Link>
                    </FooterNavItem>

                    <FooterNavItem header="Recursos">
                        <Link to="/resources/calculators">Calculadoras</Link>
                        <Link to="/resources/tables">Tabelas de Conversão</Link>
                        <Link to="/resources/trees">Árvores de Decisão</Link>
                        <Link to="/resources/guidelines">Guidelines</Link>
                        <Link to="/resources/infos">Infografias</Link>
                        <Link to="/resources/leaflets">Folhetos</Link>
                        <Link to="/resources/exercises">Exercícios</Link>
                    </FooterNavItem>

                    <LanguageForm/>

                </footer>
            </Container>
        </div>
        // Next
    );
}

const LanguageForm = () => {
    const formKey = useFormKey();

    const render = false;
    if (!render)
        return null;

    return (
        <Form className="change-language" formKey={formKey} onSubmit={console.log}>
            <FormSelect
                name="language"
                onChange={console.log}
                values={["PT"]}
                labels={["Português"]}
                initialValue="PT"
            />
        </Form>
    )
}

const FooterNavItem = (props) => (
    <div className="footer-nav-item">
        <h2>{props.header}</h2>
        {props.children}
    </div>
)

export default FooterNav;
