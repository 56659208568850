import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import Collapsible from "react-collapsible";
import {Link, useLocation} from "react-router-dom";

import {useSpecialties} from "../../../api/SpecialtyAPI";
import ShadowCard from "../../card/ShadowCard";
import Collapsable from "../../collapsable/Collapsable";
import RegularHeading from "../../header/RegularHeading";
import ImageFluid from "../../image/ImageFluid";
import { AllIcon, CalculatorIcon, ConverterIcon, FavoriteIcon, GuidelineIcon, InfographIcon, PopularIcon,TreesIcon } from "../icons/ResourceIcons";
import ResourceTypeToName from "./ResourceTypeToName";
import styles from './ResourcesSidebar.module.css';

const classNames = require("classnames");
const icons = {
    "calculators": <CalculatorIcon/>,
    "trees": <TreesIcon/>,
    "tables": <ConverterIcon/>,
    "guidelines": <GuidelineIcon/>,
    "infos": <InfographIcon/>
}

const ResourcesSidebar = (props) => {
    const location = useLocation();
    const selectedTheme = new URLSearchParams(location.search).get("theme");
    const resourceType = props.resourceType;

    const [ specialties ] = useSpecialties();

    const specialtyTrigger = (
        <h3 className={styles.specialtiesHeader}>Especialidades <KeyboardArrowDownIcon/></h3>
    )

    return (
        <nav className={styles.sidebar}>
            <RegularHeading className={styles.heading}>
                {icons[resourceType]}
                <ResourceTypeToName resourceType={resourceType}/>
            </RegularHeading>

            <ul className={styles.mainNav}>
                {navbarItems.map(item => (
                    <li key={item.label}>
                        <NavbarItem
                            link={`/resources/${resourceType}#${item.hash}`}
                            icon={item.icon}
                            label={item.label}
                            selected={location.hash ? location.hash.includes(item.hash) : item.default}
                        />
                    </li>
                ))}
            </ul>

            <Collapsible trigger={specialtyTrigger} contentOuterClassName={styles.specialtiesContainer} triggerOpenedClassName={styles.specialtiesOpen}>
                <ul className={styles.specialties}>
                    {specialties.map(specialty => (
                        <li key={specialty.id}>
                            <NavbarSpecialty
                                specialty={specialty}
                                resourceType={resourceType}
                                selectedTheme={selectedTheme}
                            />
                        </li>
                    ))}
                </ul>
            </Collapsible>

        </nav>
    )
}

const NavbarSpecialty = ({ specialty, resourceType, selectedTheme }) => {
    const isSpecialtySelected = specialty.Themes.some(theme => String(theme.id) === selectedTheme);

    const trigger = (
        <span className={classNames(styles.specialty, { [ styles.selected]: isSpecialtySelected })}>
            <img src={specialty.icon} alt={specialty.name}/>
            <span>{specialty.name}</span>
        </span>
    )

    return (
        <ShadowCard>
            <Collapsable title={trigger} buttonClassName={styles.specialtyTrigger} contentClassName={styles.themes}>
                <ul>
                    {specialty.Themes.map(theme => (
                        <li key={theme.id}>
                            <Link to={`/resources/${resourceType}?theme=${theme.id}#specialty`}
                                  className={classNames(styles.specialtyLink, { [styles.selected]: String(theme.id) === selectedTheme })}
                            >
                                {theme.name}
                                {theme.Stand &&
                                    <ImageFluid src={theme.Stand.Sponsor.logo} alt={theme.name + " Sponsor"}/>
                                }
                            </Link>
                        </li>
                    ))}
                </ul>
            </Collapsable>
        </ShadowCard>
    )
}

const NavbarItem = ({ label, icon, link, selected }) => (
    <Link to={link}>
        <ShadowCard className={classNames(styles.navbarItem, { [styles.selected]: selected })}>
            {icon}
            <span>{label}</span>
        </ShadowCard>
    </Link>
)

const navbarItems = [
    { label: "Popular", hash: "popular", icon: <PopularIcon/> },
    { label: "Favoritos", hash: "favorite", icon: <FavoriteIcon/> },
    { label: "Todos", hash: "all", icon: <AllIcon/>, default: true },
    // { label: "Novos", hash: "new", icon: newIcon}
]

export default ResourcesSidebar;