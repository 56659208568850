import React from "react";

import AccessibleDivButton from "../other/AccessibleDivButton";
import styles from "./LetterList.module.css";

const classNames = require("classnames");

const LetterList = ({ selected, onSearch, className }) => {
    const letters = ["0-9"].concat("ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(''));

    return (
        <ul className={classNames(styles.letters, className)}>
            {letters.map(letter => {
                let isSelected = selected === letter;
                return (
                    <li key={letter} className={classNames({[styles.selected]: isSelected})}>
                        <AccessibleDivButton onClick={() => onSearch(letter)}>
                            {letter}
                        </AccessibleDivButton>
                    </li>
                )
            })}
        </ul>
    )
}

export default LetterList;