import React  from "react";
import { Redirect } from "react-router-dom";

import { useAuth } from "./AuthProvider";

const NoLoggedUser = (props) => {
    const user = useAuth()

    if (user)
        return <Redirect to="/"/>

    return <>{props.children}</>
}

export default NoLoggedUser;