import './FormInput.css'

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React from "react";

import useBool from "../../hooks/useBool";
import FormControl from "./FormControl";

const FormInput = FormControl(({onChange, focusOnRender, type, ...otherProps}) => {
    const [isShowingPassword, show, hide] = useBool(false); // state only used  if type is "password"

    const PasswordToggle = isShowingPassword ? <VisibilityIcon onClick={hide}/> : <VisibilityOffIcon onClick={show}/> ;

    return (
        <div className="form-input-wrapper">
            <input
                ref={focusOnRender ? (input => input && input.focus()) : null}
                className="form-input"
                type={type === "password" && isShowingPassword ? "text" : type}
                onChange={(event) => onChange(event.target.value)}
                {...otherProps}
            />

            { type === "password" && <div className="visibility-icon">{PasswordToggle}</div>}
        </div>
    )
});

export default FormInput;

FormInput.defaultProps = Object.assign({}, FormInput.defaultProps, {
    type: "text"
});
