/* eslint  import/no-unresolved: 0 */
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";
//import * as Sentry from "@sentry/react";

// import { Provider, ErrorBoundary } from "@rollbar/react";
import React from "react";
import ReactDOM from "react-dom";

import PublicApp from "./apps/PublicApp";
import * as serviceWorker from "./serviceWorker";

// const tracePropagationTargets = process.env.REACT_APP_TRACE_PROPAGATION_TARGETS
//   ? process.env.REACT_APP_TRACE_PROPAGATION_TARGETS.split(',')
//   : [];

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_KEY,
//   debug: true,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   tracePropagationTargets: tracePropagationTargets,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });
// const rollbarConfig = {
//   accessToken: process.env.REACT_APP_ROLLBAR_KEY,
//   captureUncaught: true,
//   captureUnhandledRejections: true,
//   environment: "production",
// };

ReactDOM.render(<PublicApp/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
