import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import React from "react";

import Form from "../forms/Form";
import FormInput from "../forms/FormInput";
import FormSubmit from "../forms/FormSubmit";
import useFormKey from "../forms/useFormKey";
import styles from "./SearchBar.module.css";

const SearchBar = ({ placeholder, onSearch, initialValue }) => {
  const formKey = useFormKey();

  return (
    <Form
      className={styles.form}
      formKey={formKey}
      onChange={onSearch}
      onSubmit={onSearch}
    >
      <FormInput
        className={styles.input}
        name="query"
        placeholder={placeholder}
        initialValue={initialValue || ""}
      />
      <FormSubmit className={styles.submit}>
        <SearchIcon className={styles.icon} />
      </FormSubmit>
    </Form>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  placeholder: "Procurar",
};

export default SearchBar;
