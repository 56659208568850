import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import {checkUser} from "../../api/AuthAPI";

const AuthContext = React.createContext();

const AuthProvider = (props) => {
    const history = useHistory();
    const [user, setUser] = useState(undefined);

    const refreshUser = () => {
        checkUser().then(setUser).catch(console.error)
    }
    
    useEffect(() => {
        history.listen(refreshUser);
        refreshUser();
    }, [history])


    return (
        <AuthContext.Provider value={user}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
  };
