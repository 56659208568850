import PropTypes from "prop-types";
import React from "react";

let classNames = require('classnames')

function makeErrorMessage(error) {
    if (!error)
        return "";
    if (typeof error === "string")
        return error;
    if (error.response && error.response.data) {
        error = error.response.data;
        if (typeof error.message === "string")
            return error.message;
        else if (Array.isArray(error.errors) && error.errors.length > 0)
            return `${error.errors[0].param}: ${error.errors[0].msg}`;
        else return "Ocorreu um erro: " + JSON.stringify(error);
    }
}

const FormKeyContext = React.createContext(null);

const Form = (props) => {
    const handleSubmit = (e) => {
        e.preventDefault()

        if (!props.formKey.validate())
            return;

            props.formKey.notifySubmitStart();
        props.onSubmit(props.formKey.getValues(), (error) => {
            props.formKey.notifySubmitFinish(makeErrorMessage(error))
        });
    }

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;

        switch (target.type) {
            case "checkbox": return props.onChange(name, target.checked);
            case "file": return props.onChange(name, target.files);
            default: return props.onChange(name, target.value);
        }
    }

    return (
        <form
            onSubmit={handleSubmit}
            onChange={handleChange}
            style={props.style}
            className={classNames(props.className, "form-container")}
        >
            <FormKeyContext.Provider value={props.formKey}>
                {props.children}
            </FormKeyContext.Provider>
        </form>
    )
}

export default Form;

export {FormKeyContext}

Form.propTypes = {
    formKey: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.any,
    className: PropTypes.string,
};

Form.defaultProps = {
    onChange: () => { }
}