
import React from "react";
import { Detector } from "react-detect-offline";
import {AttentionIcon} from "../adhara/icons/CommonIcons";
import ButtonPrimary from "../buttons/ButtonPrimary";
import ShadowCard from "../card/ShadowCard";
import styles from './NetworkDetector.module.css';

const classNames = require("classnames");
const NetworkDetector = () => (
    <Detector  render={({ online }) => (
        <ShadowCard className={classNames(styles.detector, {[styles.online]: online})}>
            <div className={styles.icon}>
                <AttentionIcon/>
            </div>
            <div className={styles.info}>
                <h3>A tua ligação à internet é instável</h3>
                <p>Por favor verifica a estabilidade da tua conexão e atualiza esta página</p>
                <ButtonPrimary onClick={() => window.location.reload(false)}>Atualizar</ButtonPrimary>
            </div>
        </ShadowCard>
    )} polling={{ url: '/ping'}}>

    </Detector>
)

export default NetworkDetector;