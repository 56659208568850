import "./Brand.css";

import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";

import {LogoBig, LogoSmall} from "../icons/CommonIcons";

let classNames = require("classnames");

const Brand = (props) => {
    const { size, className, center, ...otherProps} = props;

    const classes = classNames("brand-name", className, {
        "brand-name-small": size === "small",
        "brand-name-big": size === "big",
        "brand-name-center": center,
    })

    return (
        <Link to="/" className={classes} {...otherProps}>
            <h1>{size === "large" ? <LogoBig/> : <LogoSmall/>}</h1>
        </Link>
    )
}

Brand.propTypes = {
    size: PropTypes.oneOf(['small', 'large']).isRequired,
}

export default Brand