import React from "react";

import ButtonBase from "./ButtonBase";

const ButtonLight = ({ style, ...otherProps}) => {
    const styles = Object.assign({
        "--hover-color": "var(--primary-faded)",
        backgroundColor: "white",
        color: "var(--primary)",

        borderRadius: "6px",
        padding: "0.25em 1.5em",

        WebkitBoxShadow: "0 0 5px -1px var(--primary)",
        MozBoxShadow: "0 0 5px -1px var(--primary)",
        BoxShadow: "0 0 5px -1px var(--primary)",

    }, style);

    return <ButtonBase style={styles} {...otherProps}/>
}

export default ButtonLight;

