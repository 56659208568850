import { React, useState } from "react"
//import {fetchResourcesByFilter } from "../api/ResourceAPI";
import {
    fetchResourcesByFilter,
    fetchResourcesByTheme,
    fetchResourcesByQuery
} from "../api/ResourceAPI";
import APIFetcher from "../components/loading/APIFetcher";
import useHash from "../hooks/useHash";
import useQuery from "../hooks/useQuery";
import ResourceLayout from "../layouts/ResourceLayout";
import LeafletItem from "./LeafletItem"


const LeafletList = () => {
    // const { resourceType } = useParams();
    const resourceType = "leaflets"
    const { query, letter, theme } = useQuery();
    const hash = useHash(); // specialty, all, popular, favorite

    const [resources, setResources] = useState([]);
    const [usePagination, setPagination] = useState(true)

    const fetchItems = (page) => {
        const limit = 20;
        const offset = page * limit;

        const callback = (resources) => {
            if (page === 0)
                setResources(resources)
            else setResources(prevResources => prevResources.concat(resources))

            if (resources.length < limit)
                setPagination(false);
        }

        if (query)
            return fetchResourcesByQuery(resourceType, query, limit, offset).then(callback);
        if (hash === "specialty")
            return fetchResourcesByTheme(resourceType, theme, limit, offset).then(callback)
        return fetchResourcesByFilter(resourceType, hash, letter, limit, offset).then(callback)
    }
    

    return (
            <ResourceLayout resourceType={resourceType} >
            <APIFetcher fetchItems={fetchItems} hasItems={resources.length > 0} reloadOnChange={[resourceType,hash,query,letter,theme]} enablePagination={usePagination}>
                <LeafletItem resources={resources} resourceType={resourceType} />
            </APIFetcher>
            </ResourceLayout>
    );
}


export default LeafletList;
