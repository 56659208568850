import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import React from "react";

import FooterContacts from "../components/navigation/FooterContacts";
import FooterNav from "../components/navigation/FooterNav";
import TopBar from "../components/navigation/TopBar";
import AppLayout from "./AppLayout";

const RegularLayout = ({ title, description, children, disableContainer}) => {
    return (
        <AppLayout title={title} description={description}>
            <TopBar/>
            {disableContainer ?
                <div style={{ flexGrow: 1, paddingBottom:'2em' }}>{children}</div> :
                <Container style={{ flexGrow: 1, maxWidth: "1200px", paddingBottom:'2em'}}>{children}</Container>
            }
            <FooterNav/>
            <FooterContacts/>
        </AppLayout>
    );
}

RegularLayout.propTypes = {
    title: PropTypes.string.isRequired
}


export default RegularLayout;