import React from "react";
import {useLocation} from "react-router-dom";

import AuthLayout from "../../layouts/AuthLayout";
import RegularLayout from "../../layouts/RegularLayout";

const ImportFallback = () => {
    const location = useLocation();
    if (location.pathname.startsWith("/admin"))
        return null
    if (location.pathname.startsWith("/register") || location.pathname.startsWith("/login"))
        return <AuthLayout title="..."/>
    return (
        <RegularLayout title="Loading">
            &nbsp;
        </RegularLayout>
    )
}

export default ImportFallback;