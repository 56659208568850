import PropTypes from "prop-types";
import React from "react";

const ImageFluid = ({src, alt, className, style}) => {
    let imgStyle = Object.assign({
        width: "100%",
        height: "auto",
    }, style);

    return (
        <img className={className} src={src} alt={alt} style={imgStyle}/>
    );
};

ImageFluid.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

export default ImageFluid;