import {Container} from "@material-ui/core";
import CircularProgressIndicator from "@material-ui/core/CircularProgress"
import PropTypes from "prop-types";
import React from "react";

const sizeToNum = {
    small: 20,
    medium: 40,
    large: 60,
}

const CircularProgress = (props) => {
    const {size, color, style, children, ...otherProps} = props;

    let containerStyle = Object.assign({
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }, props.style);

    let indicatorStyle = {color: color, margin: props.style?.margin ? props.style.margin : "0 1em"};

    return (
        <Container style={containerStyle}>
            <CircularProgressIndicator size={sizeToNum[size]} style={indicatorStyle} {...otherProps}/>
            {children}
        </Container>
    )
}

CircularProgress.defaultProps = {
    color: "var(--primary)",
    size: "medium",
}

CircularProgress.propTypes = {
    size: PropTypes.oneOf(Object.keys(sizeToNum)),
}

export default CircularProgress;
