import { IconButton, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import ArrowDropDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PersonIcon from "@material-ui/icons/Person";
import StarIcon from "@material-ui/icons/Star";
import React, { useState } from "react";
import Collapsible from "react-collapsible";
import { Link, useHistory } from "react-router-dom";

import { signOut } from "../../api/AuthAPI";
import useBool from "../../hooks/useBool";
import Brand from "../adhara/brand/Brand";
import { TrainingsIcon } from "../adhara/icons/AdminIcons";
import { CartIcon, HeartIcon } from "../adhara/icons/CommonIcons";
import { useAuth } from "../auth/AuthProvider";
import ButtonLight from "../buttons/ButtonLight";
import ButtonPrimary from "../buttons/ButtonPrimary";
import ConfirmedButton from "../buttons/ConfirmedButton";
import CircularProgress from "../loading/CircularProgress";
import { useShoppingCart } from "../shopping/CartProvider";
import HamburgerNavbar from "./HamburgerNavbar";

import MainNavbar from "./MainNavbar";
import styles from "./TopBar.module.css";

const TopBar = () => {
  const user = useAuth();
  const cartSize = useShoppingCart().items.length;

  // Normal navbar
  const [collapsibleContent, setCollapsibleContent] = useState();

  // Hamburger navbar
  const [hamburgerContent, setHamburgerContent] = useState();
  const [isHamburgerOpen, openHamburger, closeHamburger] = useBool(false);
  const toggleHamburger = () =>
    isHamburgerOpen ? closeHamburger() : openHamburger();

  return (
    <header
      className={styles.topbarContainer}
      onMouseLeave={() => setCollapsibleContent(null)}
    >
      <div className={styles.topbar}>
        <Brand size="large" className={styles.topbarLogo} />
        <Brand size="small" className={styles.topbarIcon} />

        <MainNavbar openCollapsible={setCollapsibleContent} />

        {/* search with flex grow */}

        <div className={styles.links}>
          {user && (
            <Link className={styles.favLink} to="/profile/items/trainings">
              <HeartIcon className={styles.cartIcon} />
            </Link>
          )}

          <Link className={styles.shoppingCartLink} to="/cart">
            {cartSize > 0 && (
              <div className={styles.shoppingCartSize}>
                {cartSize >= 100 ? "+99" : cartSize}
              </div>
            )}
            <CartIcon className={styles.cartIcon} />
          </Link>

          <Link
            className={styles.trainingsLinkMobile}
            to="/profile/items/trainings"
          >
            <TrainingsIcon className={styles.cartIcon} />
          </Link>
        </div>
        <HamburgerNavbar
          isOpen={isHamburgerOpen}
          toggle={toggleHamburger}
          setHamburgerContent={setHamburgerContent}
        />

        <div className={styles.authButtons}>
          <AuthButtonLeft user={user} />
          <AuthButtonRight user={user} />
        </div>
      </div>

      <TopBarCollapsible open={true}>
        <Container style={{ padding: 0 }}>{collapsibleContent || ""}</Container>
      </TopBarCollapsible>

      <TopBarCollapsible open={isHamburgerOpen}>
        {hamburgerContent}
      </TopBarCollapsible>
    </header>
  );
};

const TopBarCollapsible = ({ open, children }) => (
  <Collapsible
    open={open}
    trigger=""
    triggerDisabled={true}
    transitionTime={200}
    tabIndex={0}
    className={styles.topbarCollapsible}
    openedclassName={styles.topbarCollapsible}
  >
    {children}
  </Collapsible>
);

const AuthButtonLeft = ({ user }) => {
  if (user === null)
    // no user
    return <ButtonLight to="/login">Log in</ButtonLight>;
};

const AuthButtonRight = ({ user }) => {
  const history = useHistory();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const logout = () =>
    signOut()
      .then(() => (window.location.href = "/"))
      .catch(console.error);

  const handleChangeMenuState = (state) => {
    setIsMenuOpen(!isMenuOpen);
  };

  if (user === null)
    // no user
    return <ButtonPrimary to="/register">Registo</ButtonPrimary>;

  if (user || !user) {
    return (
      <>
        <ButtonLight
          className={styles.trainingsLinkDesktop}
          style={{ borderRadius: "20px" }}
          to="/profile/items/trainings"
        >
          Os meus Cursos
        </ButtonLight>
        <div
          className={styles.settingsMenu}
          onMouseLeave={() => handleChangeMenuState()}
        >
          <IconButton
            className={styles.iconButton}
            tabIndex={0}
            role="button"
            aria-controls="settings-menu"
            aria-haspopup="true"
            onMouseEnter={() => handleChangeMenuState()}
          >
            {user && (
              <>
                {user.roles.includes("influencer") && (
                  <div className={styles.partnerIconContainer}>
                    <StarIcon className={styles.partnerIcon} />
                  </div>
                )}
                <PersonIcon className={styles.icon} />
                <div className={styles.dropdownIconContainer}>
                  <ArrowDropDownIcon className={styles.dropdownIcon} />
                </div>
              </>
            )}

            {!user && (
              <>
                <CircularProgress
                  color="white"
                  size="small"
                  style={{ margin: "0", padding: 0 }}
                />
              </>
            )}
          </IconButton>
          {isMenuOpen && user && (
            <div className={styles.menuContainer}>
              <div id="menu" className={styles.menu}>
                {user.roles.includes("influencer") && (
                  <Button
                    type={"button"}
                    onClick={() => history.push("/partner/details")}
                    className={styles.menuItem}
                  >
                    <div className={styles.label}>Parceria</div>
                  </Button>
                )}

                <div className={styles.menuItemBottomDivision} />

                <Button
                  type={"button"}
                  onClick={() => history.push("/profile/details")}
                  className={styles.menuItem}
                >
                  <div className={styles.label}>Editar Perfil</div>
                </Button>

                <div className={styles.menuItemBottomDivision} />

                <ConfirmedButton
                  className={styles.menuItem}
                  confirmText="Deseja mesmo terminar sessão?"
                  confirmTitle="Sair"
                  onClick={logout}
                >
                  <div className={styles.label}>Sair</div>
                </ConfirmedButton>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
};

TopBar.defaultProps = {
  loggedIn: false,
};

export default TopBar;
