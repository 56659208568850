import React from "react";

const ShadowCard = (props) => {
    const {style, children, width, height, blur, spread, borderRadius, ...otherProps} = props;
    const divStyle = Object.assign({
        height: height,
        width: width,
        borderRadius: borderRadius,
        WebkitBoxShadow: `0 0 ${blur} ${spread} rgba(192,192,192,0.35)`,
        MozBoxShadow: `0 0 ${blur} ${spread} rgba(192,192,192,0.35)`,
        BoxShadow: `0 0 ${blur} ${spread} rgba(192,192,192,0.35)`,
    }, style)
    return (
        <div style={divStyle} {...otherProps}>{children}</div>
    )
}

export default ShadowCard;

ShadowCard.defaultProps = {
    blur: "12px",
    spread: "3px",
    borderRadius: "6px",
    width: "auto",
    height: "auto",
}
