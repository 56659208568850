import './HamburgerNavbar.css'

import React, {useEffect} from "react";
import {Link, useLocation} from "react-router-dom";

import {useAuth} from "../auth/AuthProvider";

const classNames = require("classnames")

const HamburgerNavbar = ({ isOpen, toggle, setHamburgerContent }) => {
    const isAdmin = useAuth()?.isAdmin;

    useEffect(() => {
        setHamburgerContent(
            <div className="hamburger-navbar-content">
                <NavbarItem to="/events">Eventos</NavbarItem>
                <NavbarItem to="/courses">Cursos Online</NavbarItem>
                <NavbarItem to="/resources">Recursos</NavbarItem>
                <NavbarItem to="/trainings?audience=0">Formações</NavbarItem>
                {isAdmin && <NavbarItem to="/admin">Admin</NavbarItem>}
            </div>
        )
    }, [isAdmin, setHamburgerContent])
    
    return (
        <nav className="hamburger-navbar">
            <button className={classNames("hamburger-icon", { "open": isOpen })} onClick={toggle}>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
            </button>
        </nav>
    )
}

const NavbarItem = ({ to, children }) => {
    const pathName = useLocation().pathname;
    const urlBase = to.slice(0, to.indexOf('?'))
    let selected = pathName.startsWith(urlBase);

    // very specific requirement
    if (pathName.startsWith("/courses/")) {
        let courseId = pathName.split("/")[2]; // could contain name
        if (courseId.split("--").length > 1)
            courseId = courseId.split("--")[1];
    }

    return (
        <Link className={classNames("hamburger-navbar-item", { "selected": selected}) } to={to}>
            {children}
        </Link>
    )
}

export default HamburgerNavbar
