import axios from "axios";
import { useState, useEffect } from "react";

import AxiosFileSender from "../lib/AxiosFileSender";
import useAPIList from "./useAPIList";

export function asArticle(tag) {
  return {
    id: tag.id,
    title: tag.name,
  };
}

export function useTypeTags(type) {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    fetchTypeTag(type).then(setTags).catch(console.error);
  }, [type]);

  return tags;
}

export function fetchTypeTag(type) {
  return axios.get(`/api/tags`, { params: { type } }).then((res) => {
    return res.data;
  });
}

export function useTags(type) {
  return useAPIList("/api/tags", { params: { type } });
}

export function deleteTag(id) {
  return axios.delete(`/api/tags/${id}`).then((res) => res.data);
}

export function addTag(body) {
  return AxiosFileSender.post("/api/tags", body).then((res) => res.data);
}

export function fetchTag(id) {
  return axios.get(`/api/tags/${id}`).then((res) => res.data);
}

export function updateTag(id, body) {
  return AxiosFileSender.put(`/api/tags/${id}`, body).then((res) => res.data);
}
