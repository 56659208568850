import axios from "axios";
import {useCallback, useEffect, useState} from "react";

import AxiosFileSender from "../lib/AxiosFileSender";

function useAPIList(baseURL, itemURL = baseURL) {
    const [items, setItems] = useState([]);

    const addItem = useCallback((body) => {
        return AxiosFileSender.post(baseURL, body).then(res => {
            setItems(prevItems => prevItems.concat([res.data]))
            return res.data;
        })
    }, [baseURL]);

    const editItem = useCallback((id, body) => {
        return AxiosFileSender.put(`${itemURL}/${id}`, body).then(res => {
            setItems(prevItems => {
                if (prevItems.find(item => item.id === id))
                    return prevItems.map(item => item.id === id ? { ...item, ...res.data } : item)
                else return prevItems.concat([res.data]);
            })
            return res.data;
        })
    }, [itemURL]);

    const deleteItem = useCallback((id) => {
        return axios.delete(`${itemURL}/${id}`).then(res => {
            setItems(prevItems => prevItems.filter(i => i.id !== id))
            return res.data;
        })
    }, [itemURL]);



    useEffect(() => {
        axios.get(baseURL).then(res => setItems(res.data)).catch(console.error)
    }, [baseURL]);

    return [items, addItem, editItem, deleteItem, setItems];
}

export default useAPIList;
