import React from "react";
import {Link} from "react-router-dom";

import styles from './LinkCard.module.css'
import ShadowCard from "./ShadowCard";

const classNames = require("classnames");
const LinkCard = ({ to, title, icon, className, ...otherProps}) => {

    return (
        <Link to={to} className={classNames(styles.link, className)} {...otherProps}>
            <ShadowCard className={styles.card}>
                <h2>{title}</h2>
                <span>{icon}</span>
            </ShadowCard>
        </Link>
    )
}

export default LinkCard;
