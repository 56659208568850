import PropTypes from "prop-types";
import React, {useState} from "react";

import SimpleModal from "../other/SimpleModal";
import ButtonPrimary from "./ButtonPrimary";

const ConfirmedButton = (props) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const {children, onClick, confirmText, confirmTitle, disabledInner, classNameInner, warning, ...otherProps} = props;

    const actionConfirmed = () => {
        setModalOpen(false);
        props.onClick()
    }
    return (
        <>
            <SimpleModal open={isModalOpen} onClose={() => setModalOpen(false)} size="small">
                <h2>{confirmTitle}</h2>
                <p>{confirmText}</p>
                <p style={{ color: "var(--error)" }}>{warning}</p>
                <ButtonPrimary onClick={actionConfirmed} disabled={disabledInner} className={classNameInner}>
                    Confirmar
                </ButtonPrimary>
            </SimpleModal>

            <ButtonPrimary onClick={() => setModalOpen(true)} {...otherProps}>{children}</ButtonPrimary>
        </>
    )
}

ConfirmedButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    confirmTitle: PropTypes.string.isRequired,
    confirmText: PropTypes.string.isRequired,
    warning: PropTypes.string,
    classNameInner: PropTypes.string,
    disabledInner: PropTypes.bool,
};

ConfirmedButton.defaultProps = {
    disabledInner: false,
}

export default ConfirmedButton;