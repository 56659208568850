import axios from "axios";
import useAPIList from "../api/useAPIList";
import AxiosFileSender from "../lib/AxiosFileSender";


export async function fetchExercises(BodypartId){
    return axios.get(`/api/resources/exercises/${BodypartId}/exercises`).then(res => res.data);
}

export function useExercises(BodypartId) {
    const [items, add, edit, deleteItem] = useAPIList(`/api/resources/exercises/${BodypartId}/exercises`);
    return [items, add, edit, deleteItem]; // edit is HTTP put with an id, so works as add
} 

export async function SendExercises(pdf, values) {
    console.log('pdf', pdf);
    return AxiosFileSender.post(`/api/resources/exercises/send`,{params: { pdf, values }}).then(res=>res.data);
}

export async function addResource(values){
    return AxiosFileSender.post(`/api/resources/leaflets`, values).then(res => res.data);
}

export async function fetchResource(resourceId) {
    return axios.get(`/api/resources/${resourceId}`).then(res => res.data);
}

export async function fetchResourcesByFilter(filter, startsWith, limit, offset) {
    return axios.get(`/api/resources/leaflets`, {params: { filter, startsWith, limit, offset }}).then(res => res.data);
}

export function deleteResource( resourceId) {
    return axios.delete(`/api/resources/leaflets/${resourceId}`).then(res => res.data)
}

export async function createResource(resourceType, resourceId) {
    return axios.post(`/create/resources/`).then(res => res.data);
}

export async function download(resourceType, resourceId) {
    return axios.get(`/${resourceId}/download`).then(res => res.data);
}

export async function SendLeaflet(resourceId, values) {
    return axios.post(`/api/resources/leaflets/${resourceId}/send`, values).then(res=>res.data);
}

export function asArticle(resource) {
    return {
        id: resource.id,
        title: resource.title,
        thumbnail : resource.Leaflet.preview
    }
}

export function updateResource(resourceType, resourceId, values) {
    return AxiosFileSender.put(`/api/resources/${resourceType}/${resourceId}`, values).then(res => res.data).catch(err => {
        const data = err.response.data;
        const errors = [
            data.inputErrors ? "Erros nos inputs:\n - " + (data.inputErrors || []).join("\n - ") : "",
            data.variableErrors ? "Erros nas variáveis:\n - " + (data.variableErrors || []).join("\n - ") : "",
            data.resultErrors ? "Erros nos resultados:\n - " + (data.resultErrors || []).join("\n - ") : "",
        ].filter(item => item).join("\n");
        if (errors)
            throw errors;
        else throw err;
    })
}