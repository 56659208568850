import React from "react";
import { useHistory} from "react-router-dom";

import useQuery from "../../../hooks/useQuery";
import CustomScrollbar from "../../scroll/CustomScrollBar";
import LetterList from "../../search/LetterList";
import SearchBar from "../../search/SearchBar";
import styles from './ResourceSearch.module.css'

const ResourceSearch = (props)  => {
    const history = useHistory();

    const baseRoute = `/resources/${props.resourceType}`;
    const { query: search, letter: currentLetter } = useQuery();

    const onSearch = (query) => {
        history.push(query ? `${baseRoute}?query=${query}` : baseRoute)
    }
    const onLetterSearch = (letter) =>  {
        history.push(letter && letter !== currentLetter ? `${baseRoute}?letter=${letter}` : baseRoute)
    }

    return (
        <CustomScrollbar>
            <div className={styles.container}>
                <LetterList onSearch={onLetterSearch} selected={currentLetter}/>
                <div className={styles.search}>
                    <SearchBar onSearch={onSearch} initialValue={search}/>
                </div>
            </div>
        </CustomScrollbar>
    )
}

export default ResourceSearch;